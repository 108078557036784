import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import logo from '../images/logo-negative.svg'

export default memo(function Error404() {
  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        <Title>Page Not Found</Title>
        <Text>Oops, we couldn't find this page!</Text>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  max-height: 3.75rem;
  margin-bottom: 5rem;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1rem;
  opacity: 0.6;
`
